<template>
  <div class='iframe' id="talkIframe">
    <div class='iframe-div' id="talkIframeDiv" v-show="isShowIframe">
      <div class="iframe-div-top">
        <span class="pointer" @click="closeEvent">关闭</span>
      </div>
      <iframe :src="url" style="frameborder:no;"  ref="iframe" id="talkIframeBox"></iframe>
    </div>
    <div class="iframe-tips" v-show="!isShowIframe" @click='open'>
      <div class='absolute iframe-tips-num' id="talkIframeUnRead"></div>
      <img class="iframe-tips-avart" src="../assets/images/operator.png" alt="">
      <span id="talkIframeStatus">宜信云</span>
      <img class="iframe-tips-ji" src="../assets/images/jiantou.png" alt="">
    </div>
  </div>

</template>

<script>
var scrollTimer = null;
import { browserRele } from "@/utils/utils";
export default {
  name:"Iframe",
  data(){
    return {
      url:"http://localhost:8080/#/home",
      iframeWin: {},
      isShowIframe:false,   //是否显示聊天框
      waitRead:2,           //新消息数量
      timer:null,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      var frame = document.getElementById('talkIframeBox');
      this.iframeWin =frame.contentWindow || frame.contentDocument.parentWindow;
      this.getMsgFromTalk();
    })
  },
  methods:{
    closeEvent(){
      this.isShowIframe = !this.isShowIframe;
      this.sendMsgToTalk("closeIframe",{})
    },
    open(){
      this.isShowIframe = !this.isShowIframe;
      this.showBig = false;
    },
    // 标题滚动
    TitleScrolling (num){
      if(num==0){
        clearInterval(scrollTimer);
        document.title = "宜信云";
        return;
      }
      
      scrollTimer = setInterval(() => {
        // 截取首字符串(第一个)
        var head = document.title.substring(0,1);
        // 截取除首字符串外所有字符串(除第一个所有)
        var foot = document.title.substring(1);
        // 头尾拼接后赋给data => tit属性
        document.title = foot + head;
        if(num==0){
          clearInterval(scrollTimer)
        }
      },400)
    },
    // 发送信息给iframe
    sendMsgToTalk(cmd,params){
      console.log('===========sendMsgToTalk==========', '')
      this.iframeWin.postMessage({
        cmd: cmd,
        params: params
      }, '*')
    },
    // 获取来自iframe的数据
    getMsgFromTalk(){
      let that = this;
      window.addEventListener('message', event=>{
        // 根据sendMsgToTalk制定的结构来解析iframe内部发回来的数据
        console.log("获取聊天页面传出来的数据",event.data);
          const data = event.data;
          switch (data.cmd) {
            case 'closeTitleScroll':    //
              console.log("===============")
              clearInterval(this.timer);
              this.waitRead = 0;
              // 业务逻辑
              break
            case 'set_W_H':
              this.setLayOut(data.params.width,data.params.height,data.params.dialogPosition)
              // 业务逻辑
              break;
            case 'getUnRead':    //获取未读消息
              this.setUnread(data.params.num);
              break
          }
      })
    },
    // 设置宽高
    setLayOut(width,height,pos){
      var talkIframe = document.getElementById("talkIframe");
      var talkIframeDiv = document.getElementById("talkIframeDiv");
      var talkIframeBox = document.getElementById("talkIframeBox");
      width = width.replace("px","") <"435" ?"435px":width;
      talkIframe.style.width = width;
      talkIframeDiv.style.height = height;
      talkIframeDiv.style.width = width;
      talkIframeDiv.style.top = pos.y;
      talkIframeDiv.style.left = pos.x;
      talkIframeBox.style.width = width;
      talkIframeBox.style.height = (height.replace('px','')-20)+"px";
    },
    // 设置未读消息
    setUnread(num){
      var read = document.getElementById("talkIframeUnRead");
      var talkIframeStatus = document.getElementById("talkIframeUnRead");
      read.style.display = num>0?"inline-block":"none";
      read.innerText = num;
      talkIframeStatus.innerText = num>0?"新消息":"宜信云";
      document.title = document.title + ` 您有${num}条未读消息`;
      this.TitleScrolling(num);
    },
  },
}
</script>

<style lang="scss" scoped>
.iframe{
  width: 435px;
  height: 100%;
  &-div{
    height: 620px;
    width: 435px;
    position: fixed;
    bottom: 1px;
    right: 5px;
    overflow: hidden;
    z-index: 888;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 11px 18px rgba(0, 0, 0, 0.3);
    iframe{
      height: 600px;
      width: 435px;
      border:none;
    }
    &-top{
      height: 30px;
      line-height: 30px;
      color: #fff;
      text-align: right;
      background-color: #076BF2;
      padding-right: 10px;
    }

  }
  &-tips{
    cursor: pointer;
    height: 100px;
    background-color:  #ff9902;
    height: 60px;
    // width: 300px;
    position: fixed;
    bottom: 1px;
    right: 5px;
    color: #fff;
    font-size: 18px;
    line-height: 60px;
    padding: 0 15px;
    border-radius: 4px;
    &-num{
      display: none;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      background-color: red;
      border-radius: 50%;
      top: -10px;
      left: -10px;
    }
    &-avart{
      height: 40px;
      width: 40px;
      vertical-align: middle;
      margin-right: 10px;
    }
    &-ji{

      margin-left: 50px;
      vertical-align: -8px;
    }
  }
}
.iframe::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}



</style>