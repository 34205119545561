// 设置浏览标题、图标
export const browserRele = (title, logo) => {
    if (process.browser) {
        // 设置浏览器标题
        document.title = title
            // 设置浏览器logo
        let link = document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = logo
        document.getElementsByTagName('head')[0].appendChild(link)

        let link1 = document.createElement('link')
        link1.rel = 'icon'
        link1.sizes = 'any'
        link1.href = logo
        document.getElementsByTagName('head')[0].appendChild(link1)
    }

}